import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Translations from './Pages/Translations'
// eslint-disable-next-line no-unused-vars
import { AdminPortalLayout, MenuItem } from '@digitalworkflow/dwreactcommon'
import '@digitalworkflow/dwreactcommon/dist/index.css'

import logoImage from './assets/images/laina-logo.png'
import logoSmImage from './assets/images/laina-logo-sm.png'

import './App.css'

const menuItems: Array<MenuItem> = [
  {
    item_type: 'Link',
    title_en: 'Dashboard',
    tooltip_en: 'Display the main dashboard',
    icon: 'fas fa-home',
    route: '/',
    view_groups: 'Everyone'
  },
  {
    item_type: 'Menu',
    title_en: 'Components',
    icon: 'fas fa-utensils',
    view_groups: 'PI, PSI',
    children: [
      {
        item_type: 'Link',
        subtitle_en: 'WorldClock',
        tooltip_en: 'Display WorldClock',
        icon: 'fas fa-clock',
        route: '/clock',
        badge_count: 10,
        view_groups: '"PI, PSI'
      },
      {
        item_type: 'Link',
        subtitle_en: 'Notepad',
        tooltip_en: 'Display Notepad',
        icon: 'fas fa-notebook',
        route: '/notepad',
        badge_count: null,
        view_groups: '"PI, PSI'
      }
    ]
  }
]

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={<AdminPortalLayout menuItems={menuItems} logoImage={logoImage} logoSmImage={logoSmImage} />}
        >
          <Route path='' element={<Translations className='page-width' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
