import React, { useCallback } from 'react'
import CardsRow from '../../components/CardsRow/CardsRow'
import Autocomplete from '../../components/Autocomplete/Autocomplete'
import DropdownBox from '../../components/Dropdown/DropdownBox'
import './Translations.css'

function TopBar() {
  const projectOptions = [
    // { id: -1, value: 'Select Project' },
    { id: 0, value: 'Project A' },
    { id: 1, value: 'Project B' }
  ]
  const languageOptions = [
    // { id: -1, value: 'Select Language' },
    { id: 0, value: 'Language A' },
    { id: 1, value: 'Language B' }
  ]

  const handleAutocompleteInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e)
  }, [])

  const handleAutocompleteOptionChange = useCallback((e: React.MouseEvent<HTMLOptionElement, MouseEvent>) => {
    console.log(e)
  }, [])

  return (
    <div className='top-bar'>
      <div className='top-bar-filters'>
        <Autocomplete
          onChange={handleAutocompleteInputChange}
          onSelect={handleAutocompleteOptionChange}
          className='responsive-autocomplete'
        />
        <DropdownBox options={projectOptions} label='Projects' className='responsive-dropdown' />
        <DropdownBox options={languageOptions} label='Languages' className='responsive-dropdown' />
        {/* <Dropdown /> */}
      </div>
      <CardsRow />
    </div>
  )
}

export default TopBar
