import React, { useEffect, useState } from 'react'
import TopBar from './TopBar'
import SideMenu from './SideMenu/SideMenu'
import TranslationContent from './TranslationContent'
import './Translations.css'

type TranslationProps = {
  className?: string
}

function Translations({ className = '' }: TranslationProps) {
  const [data, setData] = useState<{}[]>([])

  useEffect(() => {
    getData()
  }, [])

  function getData() {
    // get data api call
    setData([])
    console.log(data)
    console.log('data fetch')
  }

  return (
    <div className={`page-div ${className}`}>
      {/* <div className='top-row-wrapper'>
            </div> */}
      <TopBar />
      {/* convert to TopRow */}
      <div className='bottom-row'>
        <SideMenu />
        {/* convert to LeftColumn */}
        <TranslationContent />
      </div>
    </div>
  )
}

export default Translations
