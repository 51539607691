import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import './SideMenu.css'

type SideMenuItemProps = {
  isActive?: boolean
  id: number
  text: string
  onClick: (id: number) => void
}
// type ToolTipProps = { text: string };

function SideMenuItem({ isActive = false, text, id, onClick }: SideMenuItemProps) {
  // const text =
  //     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.';
  // const text = 'lower 12345';
  const renderTooltip = (text: string = '') => {
    return (
      <Tooltip id='tooltip'>
        <span className='tooltip-text'>{text}</span>
      </Tooltip>
    )
  }

  return (
    <OverlayTrigger placement='right' overlay={renderTooltip(text)}>
      <div className={`side-menu-item border-bottom1 ${isActive ? 'active' : ''}`} onClick={() => onClick(id)}>
        <span className='item-text'>{text}</span>
      </div>
    </OverlayTrigger>
  )
}

export default SideMenuItem
