/**
 * Capitalizes first letter of each word in a string
 *
 * @param str - String to capitalize
 *
 * @returns A refactored string
 *
 * @example
 * ```
 * const newString = refactorTitle('example test')
 * console.log(newString) //prints 'Example Test'
 * ```
 */

export default function refactorTitle(str: string): string {
  const splitStr: string[] = str.split(' ')
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i][0].toUpperCase() + splitStr[i].substring(1)
  }
  return splitStr.join(' ')
}
