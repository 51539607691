import React, { useCallback, useEffect, useState } from 'react'
import KeyCard from './KeyCard'
import keyData from '../../common/keysCount.json'
import { toggleIsActiveForArray } from '../../Utils'

function CardsRow() {
  const [data, setData] = useState<typeof keyData | null>(null)

  useEffect(() => {
    setData(keyData)
  }, [])

  const handleClick = useCallback(
    (id: number) => {
      const newData = toggleIsActiveForArray(data, id)
      setData([...newData] as typeof keyData)
    },
    [data]
  )

  return (
    <div className='d-flex align-items-center justify-content-evenly flex-wrap row-gap-2'>
      {data?.map((d, i) => (
        <KeyCard
          key={i}
          id={i}
          title={d.title}
          numberOfKeys={d.numberOfKeys}
          isActive={d.isActive}
          onClick={handleClick}
        />
      ))}
    </div>
  )
}

export default CardsRow
