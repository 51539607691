import React, { useEffect, useState } from 'react'
import { Button, Modal, Container, Col, Row } from 'react-bootstrap'
// import { Link } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa'
// import LanguageKeys from '../../components/LanguageKeys';
import '../../modal-custom.css'
const keyHeadMaxLength = 90

interface LanguageKey {
  /** Language Key Definition */
  shortCode: string
  fullCode: string
  version: number
  key: string
  // versions?: LanguageKeyVersion[];
}

// interface LanguageKeyVersion {
//     /** Language Key Definition */
//     shortCode: string;
//     fullCode: string;
//     version: number;
//     key: string;
//     updatedAt: string;
//     updatedBy: string;
// }

function TranslationContent() {
  const langKeysArr = [
    {
      shortCode: 'en',
      fullCode: 'en-US',
      version: 1,
      key: `Welcome to the portal, we are here. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.`
    },
    {
      shortCode: 'en',
      fullCode: 'en-UK',
      version: 3,
      key: `Welcome to the portal, we are here. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.`
      // versions: [
      //     {
      //         shortCode: 'en',
      //         fullCode: 'en-UK',
      //         version: 1,
      //         updatedAt: '2023-09-12 00:10:00',
      //         key: `Welcome to the portal, we are here. Lorem IPSUM DOlor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.`
      //     },
      //     {
      //         shortCode: 'en',
      //         fullCode: 'en-UK',
      //         version: 2,
      //         updatedAt: '2023-09-18 10:10:00',
      //         key: `Welcome to the portal, we are here. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.`
      //     }
      // ]
    },
    {
      shortCode: 'fr',
      fullCode: 'fr-FR',
      version: 1,
      key: `Welcome to the portal, we are here. Lorem IPSUM DOLOR SIT amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.`
    },
    {
      shortCode: 'es',
      fullCode: 'es-ES',
      version: 1,
      key: `Welcome to the portal, we are here. Lorem ipsum dolor sit amet, consectetur adipisci elit, sed eiusmod tempor incidunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.`
    }
  ]
  const langKeyforEdit: LanguageKey = {
    shortCode: '',
    fullCode: '',
    version: 0,
    key: ''
  }

  const [showMore, setShowMore] = useState(false)
  const [langKeys, setLangKeys] = useState(langKeysArr)
  const [langKeyEdit, setLangKeyEdit] = useState(langKeyforEdit)
  const [langKeyVersion, setLangKeyVersion] = useState(langKeyforEdit)
  // const [langKeyVersion, setLangKeyVersion]=useState<LanguageKeyVersion[]|[]>([]);
  // const [langKeyVersion, setLangKeyVersion] = useState<LanguageKey | null>(
  //     null
  // );
  const [showForm, setShowForm] = useState(false)
  const [showRevisions, setShowRevisions] = useState(false)

  const handleCloseForm = () => setShowForm(false)
  const handleShowForm = () => setShowForm(true)

  const handleCloseRevisions = () => setShowRevisions(false)
  const handleShowRevisions = () => setShowRevisions(true)

  const loadLangKey = (langKey: LanguageKey) => {
    setLangKeyEdit(langKey)
    handleShowForm()
    console.log(`loading lang key: `, langKey)
  }

  const loadLangKeyVersions = (langKey: LanguageKey) => {
    // setLangKeyVersion(langKey.versions);
    // setLangKeyVersion(langKey);
    setLangKeyVersion(langKey)
    handleShowRevisions()
    console.log(`loading lang key versions: `, langKey)
  }

  const fetchLangKeys = () => {
    setLangKeys(langKeysArr)
  }

  useEffect(() => {
    // Fetch users on component mount
    fetchLangKeys()
  }, [])

  return (
    <div className='content-box h-100 d-flex flex-column translation-content'>
      {langKeys ? (
        <div className='lang-key-head-container border-bottom'>
          <h6 className='lang-key-head'>
            {/* Key: */}
            {showMore ? langKeys[0].key : `${langKeys[0].key.substring(0, keyHeadMaxLength)}...`}
          </h6>
          <a id='btnShowMoreLess' className='small-link-default' onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Show less' : 'Show more'}
          </a>
        </div>
      ) : (
        '<></>'
      )}
      <div className='lang-keys-container h-100'>
        {langKeys ? (
          langKeys.map((item: LanguageKey, index: number) => (
            <div
              className='lang-key-item border-bottom1 flex-grow-1 d-flex justify-content-between align-items-center'
              key={`lang-${index}`}
            >
              <div className='text-center lang-label'>
                <h6 className='m-0 text-center'>
                  {item.fullCode} v.{item.version}
                </h6>
                <a
                  className='version-label'
                  onClick={() => {
                    loadLangKeyVersions(item)
                  }}
                >
                  Revisions
                </a>
              </div>
              <div className='text-left lang-key'>{item.key}</div>
              <div className='text-right lang-key-action'>
                <button className='btn btn-sm p-0' onClick={() => loadLangKey(item)}>
                  <FaEdit />
                </button>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      <Modal
        show={showForm}
        onHide={handleCloseForm}
        backdrop='static'
        keyboard={false}
        className='modal right_modal fade'
      >
        {/* 
                <Modal.Header closeButton>
                    <Modal.Title className='bg-primary'>
                        Edit Language Key
                    </Modal.Title>
                </Modal.Header> 
                */}
        <Modal.Body>
          <div className='lang-key-head-container'>
            <h4 className='lang-key-head'>Edit Language Key</h4>
          </div>
          <Container>
            <Row>
              <Col xs={12}>
                <div className='form-group'>
                  <label htmlFor='langKeyEdit'>
                    {langKeyEdit ? (
                      <h4>
                        {langKeyEdit?.fullCode} v {langKeyEdit?.version}
                      </h4>
                    ) : (
                      '<></>'
                    )}
                  </label>
                </div>
              </Col>
              <Col xs={12}>
                <div className='form-group'>
                  <textarea className='form-control' value={langKeyEdit?.key ?? ''} rows={10} />
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseForm}>
            Cancel
          </Button>
          <Button variant='primary'>Save</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showRevisions} onHide={handleCloseRevisions} keyboard={false} className='modal modal-xl fade'>
        <Modal.Body>
          <div className='lang-key-head-container'>
            <h4 className='lang-key-head'>Revisions</h4>
          </div>
          <Container className='revisions-container'>
            <Row className='revisions-head-row light-blue-heading'>
              <Col xs={7} className='text-left'>
                Key
              </Col>
              <Col xs={1} className='text-center'>
                Ver
              </Col>
              <Col xs={2} className='text-center'>
                Updated By
              </Col>
              <Col xs={2} className='text-center'>
                Updated At
              </Col>
            </Row>
            <Row className='revision-row'>
              <Col xs={7} className='text-left'>
                {langKeyVersion.key}
              </Col>
              <Col xs={1} className='text-center'>
                1
              </Col>
              <Col xs={2} className='text-center'>
                Recordo Powel
              </Col>
              <Col xs={2} className='text-center'>
                2023-09-12{' '}
              </Col>
            </Row>
            <Row className='revision-row'>
              <Col xs={7} className='text-justify'>
                {langKeyVersion.key}
              </Col>
              <Col xs={1} className='text-center'>
                2
              </Col>
              <Col xs={2} className='text-center'>
                Recordo Powel
              </Col>
              <Col xs={2} className='text-center'>
                2023-09-13{' '}
              </Col>
            </Row>
            {/* {langKeyVersion?.versions ? (
                            langKeyVersion.versions.map(
                                (
                                    versionRow: LanguageKeyVersion,
                                    counter: number
                                ) => (
                                    <Row
                                        className='revision-row border-bottom'
                                        key={`ver-${counter}`}
                                    >
                                        <Col xs={6}>{versionRow.key}</Col>
                                        <Col xs={2}>{versionRow.updatedBy}</Col>
                                        <Col xs={4}>{versionRow.updatedAt}</Col>
                                    </Row>
                                )
                            )
                        ) : (
                            <></>
                        )} */}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseRevisions}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default TranslationContent
