import React, { useState, useEffect } from 'react'
import keyData from '../../common/data.json'
import './Autocomplete.css'

type AutocompleteProps = {
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSelect: (e: React.MouseEvent<HTMLOptionElement, MouseEvent>) => void
}

function Autocomplete({ className = '', onChange, onSelect }: AutocompleteProps) {
  // eslint-disable-next-line no-unused-vars
  const [showList, setShowList] = useState<boolean>(false)
  const [value, setValue] = useState<string>('')
  const [data, setData] = useState<typeof keyData>([])

  useEffect(() => {
    setData(keyData)
  }, [])

  function inputFocus() {
    setShowList(true)
  }
  function inputBlur() {
    setTimeout(() => {
      setShowList(false)
    }, 250)
  }
  function inputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setValue(e.target.value)
    onChange(e)
  }
  function optionClick(e: React.MouseEvent<HTMLOptionElement, MouseEvent>) {
    setValue(e.currentTarget.value)
    onSelect(e)
  }

  return (
    <div className={`custom-autocomplete ${className}`}>
      <input
        className='autocomplete-input'
        id='keysInput'
        name='autocomplete-datalist'
        placeholder='Type to search...'
        autoComplete='off'
        role='combobox'
        list=''
        value={value}
        onFocus={() => inputFocus()}
        onBlur={() => inputBlur()}
        onChange={(e) => inputChange(e)}
      />
      <datalist
        style={{ display: showList ? 'block' : 'none' }}
        role='listbox'
        id='autocomplete-datalist'
        className='autocomplete-datalist'
      >
        {data.map((d, i) => (
          <option key={i} value={d.text} onClick={(e) => optionClick(e)} className='autocomplete-options'>
            {d.text}
          </option>
        ))}
      </datalist>
    </div>
  )
}

export default Autocomplete
