import React, { useCallback, useState, useEffect } from 'react'
// import Autocomplete from '../../../components/Autocomplete/Autocomplete';
import SideMenuItem from './SideMenuItem'
import keyList from '../../../common/keysList.json'
import { toggleIsActiveForArray } from '../../../Utils'
import './SideMenu.css'

function SideMenu() {
  const [data, setData] = useState<typeof keyList | null>(null)

  useEffect(() => {
    setData(keyList)
  }, [])

  const handleClick = useCallback(
    (id: number) => {
      const newData = toggleIsActiveForArray(data, id)
      setData([...newData] as typeof keyList)
    },
    [data]
  )

  return (
    <div className='content-box side-menu'>
      {/* <div className='autocomplete-wrapper'>
                <Autocomplete width='100%' />
            </div> */}
      <div className='list-wrapper'>
        {data?.map((d, index) => (
          <SideMenuItem key={index} id={index} isActive={d.isActive} text={d.text ?? ''} onClick={handleClick} />
        ))}
      </div>
    </div>
  )
}

export default SideMenu
