import React from 'react'
import { refactorTitle } from '../../Utils'
// import { FaKey } from 'react-icons/fa';
import './CardsRow.css'

type KeyCardProps = {
  id: number
  className?: string
  width?: string | number
  height?: string | number
  // maxHeight?: string | number;
  // minHeight?: string | number;
  numberOfKeys: string | number
  title: string
  isActive?: boolean
  onClick: (id: number) => void
}

function KeyCard({
  id,
  className = '',
  width = 100,
  height = 80,
  isActive = false,
  title,
  numberOfKeys,
  onClick
}: KeyCardProps) {
  return (
    <div
      className={'d-flex flex-column align-items-center justify-content-center card clickable' + className}
      style={{ width, height }}
      onClick={() => onClick(id)}
    >
      <span className={'key-count' + (isActive ? ' active' : '')}>{numberOfKeys}</span>
      <span className={'key-title' + (isActive ? ' active' : '')}>{refactorTitle(title)}</span>
    </div>
  )
}

export default KeyCard
