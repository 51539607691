import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import './Dropdown.css'

type DropdownProps = {
  className?: string
  label?: string
  options: { [key: string]: string | number }[]
}

function DropdownBox({ className = '', label = 'Select One', options }: DropdownProps) {
  const ddText = label
  const [ddLabel, setDdLabel] = useState<string | number>(ddText)
  // var selText = ddText;

  const handleClickOption = (optionVal: string | number) => {
    console.log(`handle click: `, optionVal)
    setDdLabel(optionVal)
  }

  return (
    // <div >
    <Dropdown className={`custom-dropdown ${className}`}>
      <Dropdown.Toggle variant='primary' id='dropdown-basic' style={{ width: '100%' }}>
        {ddLabel}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item disabled>Select {label}</Dropdown.Item>
        {options.map((opt) => (
          <Dropdown.Item
            className='select'
            key={`option-${opt.id}`}
            value={opt.id}
            // href={`#option/${opt.id}`}
            onClick={() => {
              console.log(`--- Clicked: `, opt.id, opt.value, typeof opt.value)

              handleClickOption(opt.value)
            }}
          >
            {opt.value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
    // </div>
  )
}

export default DropdownBox
